export default {
  custom: {},

  1: {
    container: {
      gridTemplateColumns: {
        default: 'repeat(2, 1fr)',
        mobileLg: '1fr'
      },
      gridTemplateAreas: {
        default: [['featuredimg', 'text']],
        mobileLg: [
          ['featuredimg'],
          ['text']
        ]
      },
      minHeight: { default: '600px', mobileLg: '500px' }
    },
    heroTextStyles: {
      textAlign: 'center',
      maxWidth: '520px',
      margin: '0 auto',
      padding: { default: ' 0 0 0 2.7rem', mobileLg: '0 2.7rem' }
    },
    copyStyles: {
      padding: { default: '0 40px', mobileSm: '0 20px' }
    },
    titleStyles: {
      marginBottom: '20px'
    },
    bodyTextStyles: {
      marginBottom: '20px'
    },
    featuredImageStyles: {
      objectFit: 'cover',
      objectPosition: 'center',
      minWidth: '100%',
      maxHeight: { default: ' ', mobileLg: '200px' },
      marginBottom: { default: ' ', mobileLg: '20px' }
    },
    bgStyles: {
      padding: { default: '0 2.7rem', mobileLg: '0' },
      marginBottom: { default: ' ', mobileLg: '40px' }
    }
  },

  2: {
    container: {
      padding: { default: '10rem 0', mobileLg: '5rem 0' },
      gridTemplateColumns: '1fr',
      gridTemplateAreas: [['text']],
      minHeight: { default: '600px', mobileLg: '500px' }
    },
    heroTextStyles: {
      textAlign: 'center',
      maxWidth: '600px',
      margin: '0 auto',
      padding: '0 2.7rem'
    },
    bgStyles: {
      padding: '0'
    },
    ctaBtnStyles: {
      borderRadius: '1em'
    },
    bodyTextStyles: {
      fontSize: '.9em'
    },
    titleStyles: {
      marginBottom: '.4em'
    }
  },

  3: {
    bgStyles: { padding: 0 },
    copyStyles: { textAlign: 'left' },
    bodyTextStyles: { marginTop: '10px', marginBottom: 0 },
    heroTextStyles: {
      padding: { default: ' 0 0 0 2.7rem', mobileLg: '0 2.7rem' }
    },
    container: {
      minHeight: '521px',
      padding: { default: '0', mobileLg: '60px 40px', mobileSm: '50px 25px' },
      gridTemplateColumns: {
        default: 'repeat(2, 1fr)',
        mobileLg: '1fr'
      },
      gridTemplateAreas: {
        default: [['featuredimg', 'text']],
        mobileLg: [
          ['text']
        ]
      }
    }
  },

  4: {
    container: {
      padding: '0 0 42.33%',
      minHeight: '0'
    },
    bgStyles: {
      padding: '0'
    }
  },

  5: {
    copyStyles: {
      display: 'none'
    },
    container: {
      minHeight: 0,
      paddingBottom: '36.36%',
      backgroundPosition: 'center'
    }
  }
};
