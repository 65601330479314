/*eslint-disable camelcase*/

import React, { Fragment } from 'react';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { bpProps } from 'utils/responsiveUtils';
import { useSelector, useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import { setActiveModal } from 'redux/actions';
import LocalizationModal from 'components/LocalizationModal';
import { ModalConductor, Icon } from '@springforcreators/propel-ui';
import tracker from 'utils/tracking';
import SocialMediaIcons from 'components/SocialMediaIcons';
import footerTemplates from './footerTemplates';
import './Footer.scss';

export const Footer = () => {
  const dispatch = useDispatch();

  const { themeData, localizationData, activeModal } = useSelector(state => state);
  const { getStyles } = useSelector(state => ({ ...bpProps(state) }));
  const {
    brand, content, styles, meta
  } = themeData;
  const {
    storeName, mailchimpUserId, mailchimpAudienceId
  } = meta;

  const {
    buyer_currency, available_currencies, fulfillment_region
  } = localizationData;

  const currency = available_currencies.find(cur => cur.currency === buyer_currency);

  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) return srcValue;
    return undefined;
  };

  const templateStyles = footerTemplates[get(styles, 'footer.template')];
  const baseFooterStyles = get(styles, 'footer');

  // Merge footer template styles & custom styles into single object
  const footerStyles = mergeWith(templateStyles, baseFooterStyles, customizer);

  const showFooterAboutSection = (
    get(content, 'footer.about.textContent') || (get(content, 'footer.showSocialIcons') && get(brand, 'socialMedia'))
  );

  let infoLinks = [
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
      external: true,
      onClick: () => tracker.track('footer.support_links.clicked'),
      label: 'Support',
      key: 'support_links'
    },
    {
      url: 'https://teespring.com/track/?utm_source=store&utm_medium=footer&utm_campaign=track_order_link',
      external: true,
      onClick: () => tracker.track('footer.track_order.clicked'),
      label: 'Track Order',
      key: 'track_order'
    },
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
      external: true,
      onClick: () => tracker.track('footer.contact_link.clicked'),
      label: 'Contact Us',
      key: 'contact_link'
    },
    {
      url: 'https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations',
      external: true,
      onClick: () => tracker.track('footer.refund_policy_link.clicked'),
      label: 'Refund Policy',
      key: 'refund_policy_link'
    }
  ];

  if (process.env.REACT_APP_SHOW_SUPPORT) {
    infoLinks = [
      {
        url: '/support',
        external: false,
        onClick: () => tracker.track('footer.support_link.clicked'),
        label: 'Support',
        key: 'support_link'
      },
      {
        url: '/track',
        external: false,
        onClick: () => tracker.track('footer.track_order.clicked'),
        label: 'Track order',
        key: 'track_order'
      },
      {
        url: '/contact',
        external: false,
        onClick: () => tracker.track('footer.contact_link.clicked'),
        label: 'Contact Us',
        key: 'contact_link'
      }
    ];
  }

  return (
    <Fragment>
      <ModalConductor
        activeModal={ activeModal }
        setActiveModal={ state => dispatch(setActiveModal(state)) }
        modals={ [{
          id: 'localization-modal',
          header: 'Preferences',
          className: 'localizationmodal',
          node: <LocalizationModal />
        }] }
      />

      <footer
        className={ `footer universal ${get(footerStyles, 'className') || ''}` }
        style={ {
          ...getStyles('bgStyles', footerStyles),
          ...getStyles('textStyles', footerStyles)
        } }
      >
        <div
          className="footer__inner"
          style={ getStyles('container', footerStyles) }
        >
          { get(content, 'footer.hero') && (
            <img
              className="footer__hero"
              alt={ get(content, 'footer.hero.title') }
              src={ getStyles('footer.hero.src', content) }
              style={ getStyles('hero', footerStyles) }
            />
          ) }

          { showFooterAboutSection && (
            <div className="footer__about" style={ getStyles('footerAboutStyles', footerStyles) }>
              { get(content, 'footer.logo') || getStyles('header.logo', content)
                ? (
                  <div className="footer__logo">
                    <img
                      src={ get(content, 'footer.logo') || getStyles('header.logo', content) }
                      alt={ storeName }
                      style={ getStyles('header.logo') }
                    />
                  </div>
                )
                : (
                  <h5
                    className="mb1"
                    style={ getStyles('titleStyles', footerStyles) }
                  >
                    { storeName }
                  </h5>
                )
              }

              { (get(content, 'footer.about.textContent') && (
                <div
                  className="mb2 footer__about-text"
                  dangerouslySetInnerHTML={
                    { __html: sanitizeHtml(get(content, 'footer.about.textContent')) }
                  }
                />
              )) }

              { get(content, 'footer.about.showReadMore') && (
                <Link className="footer__readmore" to="/about">Read more</Link>
              ) }

              { (get(content, 'footer.showSocialIcons') && get(brand, 'socialMedia'))
                && (
                  <div className="footer__socialicons">
                    <SocialMediaIcons styles={ getStyles('socialStyles', footerStyles) } />
                  </div>
                )
              }
            </div>
          ) }

          <div className="footer__menu">
            <a
              href="https://teespring.com/for/creators"
              className="footer__teespring mb1"
              onClick={ () => tracker.track('whitelabel.footer.powered_by_teespring.clicked') }
              target="_blank"
              rel="noopener noreferrer"
            >
              Powered by
              <div className="teespring-logo">
                <svg viewBox="0 0 79 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.6915 16C3.46442 16 1.7315 15.642 0 14.0868L1.35885 12.5784C3.78607 14.7604 6.31531 13.8986 7.09746 13.1699C7.15272 13.119 7.20515 13.068 7.25475 13.0157C4.69858 12.7666 2.82963 10.9582 2.70069 10.8308C0.741061 8.90776 0.297558 6.29274 1.69041 4.8791C3.08185 3.46688 5.70461 3.86451 7.66282 5.78474C7.83427 5.94888 9.7429 7.81675 10.1198 10.187C11.2335 9.35633 11.9406 8.02901 11.9406 6.57009C11.9406 5.34465 11.4673 4.20978 10.6086 3.37632C9.71456 2.50747 8.45631 2.02919 7.06487 2.02919V0C8.98767 0 10.7489 0.682055 12.0256 1.92164C13.281 3.14142 13.9725 4.79278 13.9725 6.57009C13.9725 9.26293 12.3132 11.6445 9.89026 12.6279C9.61395 13.3609 9.14495 14.0387 8.48465 14.6543C7.83711 15.2571 6.57178 15.9264 4.9593 15.9943C4.87286 15.9986 4.7836 16 4.6915 16ZM3.83708 6.05218C3.55369 6.05218 3.30998 6.12859 3.13994 6.30264C2.63976 6.81065 2.95574 8.23844 4.12755 9.38887C4.12897 9.39029 4.1318 9.39312 4.13322 9.39453C4.15022 9.4101 5.98375 11.1874 8.15309 11.0049C8.15309 11.0034 8.15309 11.002 8.15309 11.0006C8.163 9.08464 6.27847 7.26913 6.25863 7.25073C6.25438 7.24648 6.24872 7.24224 6.24588 7.23799C5.55016 6.55452 4.56255 6.05218 3.83708 6.05218Z" fill="currentColor" />
                  <path d="M27.5029 5.73243C27.3895 5.11689 26.8809 4.15606 25.4568 4.16031C24.397 4.16456 23.7041 4.8452 23.7069 5.5881C23.7097 6.20365 24.1065 6.69184 24.9297 6.8475L26.4969 7.14183C28.5373 7.52955 29.634 8.85121 29.6396 10.4304C29.6453 12.1511 28.2128 13.8945 25.6042 13.9029C22.6314 13.9143 21.3123 12.0082 21.1323 10.4134L23.1543 9.86863C23.2535 10.9738 24.0314 11.9657 25.6127 11.9601C26.7831 11.9558 27.4292 11.3686 27.4264 10.579C27.4235 9.93231 26.9319 9.42855 26.0619 9.25733L24.4947 8.94743C22.7065 8.59084 21.5475 7.44182 21.5418 5.75224C21.5347 3.76268 23.3158 2.24008 25.4342 2.23301C28.1533 2.2231 29.2032 3.86173 29.4611 5.12396L27.5029 5.73243Z" fill="currentColor" />
                  <path d="M33.906 9.42002L33.9216 13.6369L31.7395 13.6454L31.697 2.44806L35.8869 2.43249C38.1002 2.424 39.5766 3.88717 39.5837 5.90928C39.5908 7.94695 38.1257 9.40446 35.9124 9.41295L33.906 9.42002ZM35.6375 7.53376C36.7286 7.52951 37.3903 6.89557 37.3875 5.93192C37.3832 4.93714 36.7172 4.323 35.6262 4.32725L33.9032 4.33291L33.9145 7.538L35.6375 7.53376Z" fill="currentColor" />
                  <path d="M44.8321 9.32096L43.6943 9.32521L43.7098 13.6369H41.5122L41.4711 2.44947L45.8508 2.4339C48.0329 2.42541 49.43 3.92112 49.4371 5.86399C49.4428 7.42762 48.5303 8.61485 47.0609 9.04644L49.481 13.6171L47.0453 13.6256L44.8321 9.32096ZM45.4569 7.43894C46.5636 7.4347 47.2097 6.80076 47.2069 5.88522C47.204 4.93713 46.5522 4.32441 45.4456 4.32866L43.6744 4.33573L43.6858 7.44602L45.4569 7.43894Z" fill="currentColor" />
                  <path d="M63.5072 13.617L58.3864 5.69274L58.4147 13.6354L56.2326 13.6439L56.1915 2.44803L58.9106 2.43812L63.6021 9.82753L63.5752 2.42114L65.7573 2.41265L65.7984 13.6085L63.5072 13.617Z" fill="currentColor" />
                  <path d="M53.8141 13.6354L51.632 13.6439L51.5909 2.448L53.7844 2.4381L53.8141 13.6354Z" fill="currentColor" />
                  <path d="M76.9796 13.4954L76.8322 12.2162C76.3292 12.9761 75.2254 13.7374 73.5336 13.7444C70.5141 13.7557 67.9282 11.5539 67.914 7.93847C67.9012 4.32159 70.5963 2.08581 73.6328 2.07449C76.5743 2.06317 78.2095 3.77963 78.7692 5.49892L76.6849 6.24889C76.3972 5.25553 75.4592 4.10509 73.6413 4.11216C71.9494 4.11782 70.1188 5.27817 70.1287 7.93139C70.1386 10.4587 71.8035 11.7634 73.6852 11.7563C75.7568 11.7492 76.5588 10.3568 76.682 9.56578L73.1397 9.57851L73.1326 7.68376L78.7295 7.66395L78.7508 13.4911L76.9796 13.4954Z" fill="currentColor" />
                </svg>
              </div>
            </a>
            <ul style={ getStyles('menuStyles', footerStyles) }>
              { infoLinks
                .filter(link => link.condition === undefined || link.condition === true)
                .map((link) => {
                  const linkElem = link.external
                    ? (
                      <a href={ link.url } onClick={ link.onClick }>{ link.label }</a>
                    )
                    : (
                      <Link to={ link.url } onClick={ link.onClick }>
                        { link.label }
                      </Link>
                    );

                  return (
                    <li key={ link.key }>
                      { linkElem }
                    </li>
                  );
                }) }
            </ul>
          </div>

          { get(content, 'footer.newsletter') && (
            <div className="footer__newsletter" style={ getStyles('newsletterStyles', footerStyles) }>
              <h5>Newsletter</h5>
              <p className="footer__newsletter__subtext">
                { get(content, 'footer.newsletterText') || 'Keep up to date to the latest products and offers' }
              </p>
              <form
                action={ `https://teespring.us20.list-manage.com/subscribe/post?u=${mailchimpUserId}&amp;id=${mailchimpAudienceId}` }
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate={ true }
              >
                <input type="text" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="example@example.com" />
                <input
                  type="submit"
                  onClick={ () => tracker.track('footer.newsletter_signup.clicked') }
                  value="SIGN UP"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="submit-button border--primary bg--primary"
                />
              </form>
            </div>
          ) }
        </div>

        <div className="footer__bottom">
          <div className="footer__bottom__inner">
            <div className="footer__bottomleft">
              <div className="footer__copyright">
                { get(content, 'footer.copyright') && (
                  <span className="mr2">
                    { get(content, 'footer.copyright') }
                  </span>
                )}
                <ul>
                  <li className="mr2"><Link to="/terms-of-use">Terms of use</Link></li>
                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
              </div>
            </div>

            <div className="footer__localization">
              <span className="mr2">Shipping region:</span>
              <button
                type="button"
                className="localizationcontrols"
                onClick={ () => dispatch(setActiveModal('localization-modal')) }
              >
                <span>
                  { fulfillment_region }
                  <Icon name="ChevronDown" className="mx1" size={ 8 } />
                </span>
                <span>
                  { `${currency?.symbol} ${currency?.currency}` }
                  <Icon name="ChevronDown" className="mx1" size={ 8 } />
                </span>
                {/* TODO Disable language selector until functionality is build */}
                {/* <span>
                  { language.local_name }
                  <Icon name="ChevronDown" size={12} />
                </span> */}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
