import React, { useState } from 'react';
import { Tabs } from '@springforcreators/propel-ui';
import sanitizeHtml from 'sanitize-html';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import some from 'lodash/some';
import ProductDetails from 'components/ProductDetails';
import DeliveryReturns from 'components/DeliveryReturns';
import tracker, { tiktok } from 'utils/tracking';
import { number, string } from 'prop-types';
import { useCookies } from 'react-cookie';
import './ProductDetailTabs.scss';

const ProductDetailTabs = ({
  activeProductIndex,
  productId,
  listingSlug,
  description,
  showPreorderForm
}) => {
  const [activeTab, setActiveTab] = useState('description');
  const { storeListings } = useSelector(state => state);
  const { productDetails } = storeListings;
  const deliveryDetails = get(
    get(storeListings[listingSlug], `products[${activeProductIndex}]`),
    'deliveryDetails'
  );
  const details = get(productDetails, productId, {});
  const validFields = ['description', 'materials', 'fit'];
  const hasDetails = some(validFields, key => details[key]);
  const [{ _teespring_partner_attribution: partnerCookie }] = useCookies();
  const trackPartnersCookieInParams = tiktok.trackPartnersCookieInParams(partnerCookie);
  const tabs = [];
  if (description) {
    tabs.push({
      id: 'description',
      label: 'Description',
      content: (<div
        dangerouslySetInnerHTML={ { __html: sanitizeHtml(description) } }
      />)
    });
  }
  if (details && hasDetails) {
    tabs.push({
      id: 'productDetails',
      label: 'Product Details',
      content: <ProductDetails
        productId={ productId }
        productDetails={ productDetails }
      />
    });
  }
  if (deliveryDetails && !showPreorderForm) {
    tabs.push({
      id: 'deliveryDetails',
      label: 'Delivery Details',
      content: <DeliveryReturns
        storeListings={ storeListings }
        listingSlug={ listingSlug }
        activeProductIndex={ activeProductIndex }
      />
    });
  }

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    tracker.track(
      `product_page.${tabId}_tab.clicked`,
      trackPartnersCookieInParams
    );
  };

  const renderTabContent = (tab) => {
    return tab ? (
      <div className="product-detail-tabs__content pb3" key={ tab.id }>
        { tab.content }
      </div>
    ) : null;
  };

  return (
    <div className="product-detail-tabs">
      <Tabs
        items={ tabs.map(tab => ({
          id: tab.id,
          label: tab.label
        })) }
        onChange={ tabId => handleTabChange(tabId) }
      />
      { renderTabContent(tabs.find(t => t.id === activeTab)) }
    </div>
  );
};

ProductDetailTabs.propTypes = {
  activeProductIndex: number.isRequired,
  productId: number.isRequired,
  listingSlug: string.isRequired,
  description: string.isRequired,
  showPreorderForm: number.isRequired
};

export default ProductDetailTabs;
