// Event Types
export const ADD_TO_CART = 'add-to-cart';
export const REMOVE_FROM_CART = 'remove-from-cart';
export const PRODUCT_DETAIL = 'product-detail-view';
export const PRODUCT_CLICK = 'product-click';
export const PRODUCT_IMPRESSIONS = 'product-impression';
export const PURCHASE_COMPLETE = 'purchase';

const VALID_EVENT_TYPES = [
  ADD_TO_CART,
  REMOVE_FROM_CART,
  PRODUCT_DETAIL,
  PRODUCT_CLICK,
  PRODUCT_IMPRESSIONS,
  PURCHASE_COMPLETE
];

export const initializeGTM = () => {
  const gtmIds = [process.env.REACT_APP_GTM_CONTAINER_ID, process.env.REACT_APP_SECONDARY_GTM_CONTAINER_ID];

  for (let i = 0; i < gtmIds.length; i += 1) {
    const gtmId = gtmIds[i];
    if (gtmId) {
      const script = document.createElement('script');
      const noscript = document.createElement('noscript');

      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}')`;

      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      document.head.insertBefore(script, document.head.childNodes[0]);
      document.body.insertBefore(noscript, document.body.childNodes[0]);
    }
  }
};

export const createEventBody = (eventType, body, paymentOption, secondaryEvent, affiliateCode) => {
  const baseEvent = {
    storefront_type: 'branded',
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: eventType,
    ecommerce: {},
    affiliateCode
  };

  const eventBody = {
    ...baseEvent,
    ...secondaryEvent,
    ecommerce: {
      ...body
    }
  };

  return paymentOption ? { ...eventBody, paymentOption } : eventBody;
};

export const pushToDataLayer = (body) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(body);
};

/**
 *
 * @param {string} eventType one of the defined constants above
 * @param {object} eventBody
 *
*/

export const pushEvent = (eventType, eventBody, paymentOption, secondaryEvent = {}, affiliateCode = undefined) => {
  if (VALID_EVENT_TYPES.includes(eventType)) {
    const gtmEvent = createEventBody(eventType, eventBody, paymentOption, secondaryEvent, affiliateCode);
    pushToDataLayer(gtmEvent);
  }
};
