import React from 'react';

const DigitalDeliveryDetails = () => (
  <>
    <p className="mb2">
      {' '}
      Instant download link will be available once order is processed.
      {' '}
    </p>
  </>
);

export default DigitalDeliveryDetails;
