import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import stripePromise from 'utils/stripeUtils';
import tracker from 'utils/tracking';
import { Alert } from '@springforcreators/propel-ui';
import {
  submitCheckout
} from 'redux/actions';
import propTypes from 'prop-types';
import PaymentForm from './PaymentForm';
import './CheckoutPayment.scss';

const CheckoutPayment = ({
  addressHasBeenEntered,
  activeSteps
}) => {
  const {
    checkout
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const checkoutError = checkout?.error;

  useEffect(() => {
    if (checkoutError) {
      tracker.track('checkout.payment.error', {
        paymentMethod: checkout?.paymentMethod,
        error: checkoutError
      });
    }
  }, [checkoutError]);

  const placeOrder = async (paymentToken, paymentId) => {
    await dispatch(submitCheckout(paymentToken, paymentId));
  };

  return (
    <>
      <Elements stripe={ stripePromise }>
        <div className="payment-page">
          {checkoutError && (
            <Alert status="error">
              <div className="payment-error">
                <p className="payment-error__title">
                  The payment cannot be authorized.
                </p>
                <p>{checkoutError}</p>
              </div>
            </Alert>
          )}

          <PaymentForm
            placeOrder={ placeOrder }
            addressHasBeenEntered={ addressHasBeenEntered }
            activeSteps={ activeSteps }
          />
        </div>
      </Elements>
    </>
  );
};

const { bool, arrayOf, string } = propTypes;
CheckoutPayment.propTypes = {
  addressHasBeenEntered: bool.isRequired,
  activeSteps: arrayOf(string).isRequired
};

export default CheckoutPayment;
