/*eslint-disable import/prefer-default-export*/

export const API_HOST = process.env.REACT_APP_BACKEND_HOST
  ? process.env.REACT_APP_BACKEND_HOST
  : 'https://commerce.teespring.com';

export const COLLECTIONS_API_PATH = `${API_HOST}/v1`;

export const USE_COLLECTIONS = process.env.REACT_APP_USE_COLLECTIONS === 'true';

export const USE_MINERVA = process.env.REACT_APP_USE_MINERVA === 'true';

export const USE_CHECKOUT_V2 = process.env.REACT_APP_USE_CHECKOUT_V2 === 'true';

export const USE_LEGACY_CHECKOUT = process.env.REACT_APP_USE_LEGACY_CHECKOUT;

export const HIDE_PROMO_CODE = process.env.REACT_APP_HIDE_PROMO_CODE === 'true';

export const DISABLE_HOLIDAY_CUTOFF = process.env.REACT_APP_HOLIDAY_CUTOFF_DISABLE === 'true';

export const GA360_TRACKING_IDS = ['UA-25627881-15', 'UA-25627881-12'];

export const CHECKOUT_HOST_V2 = process.env.REACT_APP_CHECKOUT_HOST_V2
  ? process.env.REACT_APP_CHECKOUT_HOST_V2
  : 'https://pgzc1kpg39.execute-api.us-east-1.amazonaws.com/dev';

export const PAYMENT_HOST = process.env.REACT_APP_PAYMENT_HOST ?? 'https://funou6a0cg.execute-api.us-east-1.amazonaws.com/dev';

export const CHECKOUT_STATE = {
  CREATED: 'created',
  PENDING: 'pending',
  SUCCESS: 'success',
  COMPLETED: 'completed',
  FAILURE: 'failure'
};

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const DECRYPT_KEY = process.env.REACT_APP_PREVIEW_SECRET_KEY ?? '';

export const HIDE_AFTERPAY = process.env.REACT_APP_HIDE_AFTERPAY === 'true' || !STRIPE_KEY;

export const AVAILABILITY_API_KEY = process.env.REACT_APP_AVAILABILITY_API_KEY || '';

export const AVAILABILITY_HOST = process.env.REACT_APP_AVAILABILITY_HOST || 'https://availability.spri.ng';

// The product for nft items differs between teedown and prod
export const NFT_PRODUCT_ID = API_HOST === 'https://teedown.com/api' ? 1831 : 1816;

export const ITEMS_CANNOT_BE_SHIPPED_TO_SELECTED_DESTINATION_ERROR = 'items_cannot_be_shipped_to_selected_destination';

export const COUNTRY_NOT_SUPPORTED_ERROR = 'country_not_supported';

export const LAUNCHDARKLY_ID = process.env.REACT_APP_LAUNCHDARKLY_ID
  ? process.env.REACT_APP_LAUNCHDARKLY_ID
  : '644c17666332a912b6d44fdb';

export const AGE_VERIFY_REDIRECT = process.env.REACT_APP_AGE_VERIFY_REDIRECT
  ? process.env.REACT_APP_AGE_VERIFY_REDIRECT
  : 'https://spri.ng';
