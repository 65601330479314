import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { ModalConductor, Icon } from '@springforcreators/propel-ui';
import CartConfirmation from 'pages/ListingPage/CartConfirmation';
import UnavailableItemsModal from 'components/UnavailableItemsModal';
import QuickAddToCartModal from 'components/ProductTile/ProductOptions/QuickAddToCartModal';
import { bpProps } from 'utils/responsiveUtils';
import stripePromise from 'utils/stripeUtils';
import { getCartSize } from 'utils/cartUtils';
import './HeaderCart.scss';
import QuickAddOutOfStockModal from '../../ProductTile/ProductOptions/QuickAddOutOfStockModal';

export const HeaderCart = () => {
  const { activeModal } = useSelector(state => state);
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const dispatch = useDispatch();
  const { userCart } = useSelector(state => state);
  const { userCartItem } = useSelector(state => state?.activeModal?.mProps);
  const cartSize = getCartSize(userCart) || 0;
  const [preventBackgroundScroll, setPreventBackgroundScroll] = useState(true);

  useEffect(() => {
    setPreventBackgroundScroll(activeModal.id === 'cart-confirmation');
  }, [activeModal.id]);

  return (
    <div className="header__cart">
      <Link to="/cart">
        <div className="header__cart_button pr_btn pr_btn--md btn--secondary label-s">
          <Icon name="Cart" size={ 26 } />
          <pre className="header__cart__text">Cart · </pre>
          <span className="header__cart__count">{(cartSize > 99 ? '99+' : cartSize)}</span>
        </div>
      </Link>

      <Elements stripe={ stripePromise }>
        <ModalConductor
          activeModal={ activeModal }
          preventBackgroundScroll={ preventBackgroundScroll }
          setActiveModal={ (state, props) => {
            const updatedProps = activeModal.id === 'cart-confirmation' ? { ...props, userCartItem } : props;
            dispatch(setActiveModal(state, updatedProps));
          } }
          transitionOrigin={ activeModal.id === 'cart-confirmation' && bpIsLT('mobileLg') ? 'bottom' : 'top' }
          modals={ [
            {
              id: 'cart-confirmation',
              className: 'cartconfirm',
              header: 'Added to cart',
              hideCloseBtn: true,
              node: <CartConfirmation />
            },
            {
              id: 'quick-add-confirmation',
              className: 'quick__add__modal',
              node: <QuickAddToCartModal />
            },
            {
              id: 'quick-add-out-of-stock',
              className: 'quick__add__modal__oos',
              node: <QuickAddOutOfStockModal />
            }
          ] }
        />
        <ModalConductor
          activeModal={ activeModal }
          setActiveModal={ (state, props) => {
            dispatch(setActiveModal(state, props));
          } }
          modals={ [
            {
              id: 'cart-unavailable-items',
              className: 'unavailable-items',
              node: <UnavailableItemsModal />
            }
          ] }
        />
      </Elements>
    </div>
  );
};

export default HeaderCart;
