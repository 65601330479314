import React, { useEffect, Fragment } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import CategoryBanner from 'components/CategoryBanner';
import HeroBanner from 'components/HeroBanner';
import ProductFeed from 'components/ProductFeed';
import { connect } from 'react-redux';
import get from 'lodash/get';
import propTypes from 'prop-types';

import HomeWrapper from './HomeWrapper';

const Home = (props) => {
  const { location } = props;
  useEffect(() => {
    manageZendeskVisibility(true);
  }, []);
  return (
    <Fragment>
      <HeroBanner location={ location } />
      <CategoryBanner />
      <ProductFeed title="Featured Products" />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  styles: get(state, 'themeData.styles.heroBanner'),
  meta: get(state, 'themeData.meta.seo')
});

const { shape } = propTypes;
Home.propTypes = {
  location: shape({}).isRequired
};

export default HomeWrapper(connect(mapStateToProps)(Home));
