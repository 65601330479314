/*eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for*/

import React from 'react';
import { useForm } from 'react-hook-form';
import {
  SelectField,
  InputField,
  Button
} from '@springforcreators/propel-ui';
import { Link, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './SupportContact.scss';

const schema = yup.object({
  'contact-ordernumber': yup.string().required('Please enter your order number'),
  'contact-email': yup.string().email('Please enter a valid email').required('Please enter a valid email'),
  'contact-fullname': yup.string().required('Please enter your full name'),
  'contact-reason': yup.string().required('Please select the reason for your contact'),
  'contact-reason-message': yup.string().required('Please tell us why you are contacting us')
}).required();

export const SupportContact = () => {
  const {
    handleSubmit,
    register,
    formState,
    getValues
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  const history = useHistory();

  // TODO handle onSubmit
  const onSubmit = (values) => {
    /*eslint-disable-next-line no-console */
    console.log(values);
    history.push('/contact-success');
  };

  const reasonItems = [
    { label: 'I have a question about my order', id: 'order-question-1' },
    { label: 'Reason 2', id: 'order-question-2' },
    { label: 'Reason 3', id: 'order-question-3' }
  ];

  return (
    <form className="support__contact" onSubmit={ handleSubmit(onSubmit) }>
      <h1 className="mb1">Contact us</h1>
      <p className="mb4">What can we help you with?</p>

      <InputField
        label="Order number"
        name="contact-ordernumber"
        type="text"
        required={ true }
        register={ register }
        defaultValue={ getValues('contact-ordernumber') }
        error={ formState?.errors['contact-ordernumber']?.message }
      />

      <p className="pt1"><Link to="/" className="typ--link">Where do I find my order number?</Link></p>

      <InputField
        label="Email address"
        name="contact-email"
        type="email"
        required={ true }
        register={ register }
        defaultValue={ getValues('contact-email') }
        error={ formState?.errors['contact-email']?.message }
      />

      <InputField
        label="Full name (first and last)"
        placeholder="Enter name"
        name="contact-fullname"
        required={ true }
        type="text"
        register={ register }
        defaultValue={ getValues('contact-fullname') }
        error={ formState?.errors['contact-fullname']?.message }
      />

      <SelectField
        name="contact-reason"
        label="Reason for contacting us"
        required={ true }
        register={ register }
        items={ reasonItems }
        defaultValue={ getValues('contact-reason') }
        error={ formState?.errors['contact-reason']?.message }
      />

      <InputField
        label={ getValues('contact-reason') }
        name="contact-reason-message"
        type="text"
        required={ true }
        elementType="textarea"
        register={ register }
        defaultValue={ getValues('contact-reason-message') }
        error={ formState?.errors['contact-reason-message']?.message }
      />

      {/* CAPTCHA goes here */}

      <Button
        className="mt4"
        type="submit"
        icon="ArrowRight"
      >
        Submit
      </Button>
    </form>
  );
};

export default SupportContact;
