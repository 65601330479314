import React, { useState, useEffect } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { convertToDollars } from 'utils/moneyUtils';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Loader,
  Icon,
  ModalConductor
} from '@springforcreators/propel-ui';
import { fetchCheckout, setActiveModal } from 'redux/actions';
import tracker from 'utils/tracking';
import { getCartLineItems, getCartLineItemsTotal, getCartLineItemsTotalQuantity } from 'utils/cartUtils';
import { decrypt, isCheckoutComplete, isValidCustomer } from 'utils/checkoutUtils';
import SEO from 'components/SEO';
import Price from 'components/Price';
import { bpProps } from 'utils/responsiveUtils';
import CheckoutHeader from './CheckoutHeader';
import CheckoutCartInfo from './CheckoutCartInfo';
import CheckoutCartInfoModal from './CheckoutCartInfoModal';
import CheckoutDetails from './CheckoutDetails';
import CheckoutDeliveryOptions from './CheckoutDeliveryOptions';
import CheckoutPayment from './CheckoutPayment';
import './CheckoutPage.scss';

const CheckoutPage = () => {
  const {
    checkout,
    localizationData,
    userCart,
    cartProducts,
    deliveryOptions,
    stores,
    storeListings,
    inventory
  } = useSelector(state => state);
  const { checkoutId } = useParams();
  const { search } = useLocation();
  const storeSlug = get(stores, 'slug');
  const id = new URLSearchParams(search).get('id');
  const selectedDeliveryOption = find(
    get(deliveryOptions, 'options'),
    option => option.id === get(checkout, 'deliveryOption')
  );

  const history = useHistory();

  const { bpIsGT, bpIsLT } = useSelector(state => ({ ...bpProps(state) }));
  const activeModal = useSelector(state => state.activeModal);
  const [total, setTotal] = useState(0);

  const [activeSteps, setActiveSteps] = useState(['details']);

  const [showAddressMessage, setShowAddressMessage] = useState(true);
  const [showPromoCode, setShowPromoCode] = useState(false);

  const [addressHasBeenEntered, setAddressHasBeenEntered] = useState(false);
  const [pageViewTriggered, setPageViewTriggered] = useState(false);

  const dispatch = useDispatch();

  const cartLineItems = getCartLineItems(userCart, cartProducts, inventory);
  const cartTotal = getCartLineItemsTotal(cartLineItems);
  const cartLineItemsQuantity = getCartLineItemsTotalQuantity(cartLineItems);
  const checkoutCustomer = get(checkout, 'customer');

  useEffect(() => {
    document.body.classList.add('checkout');

    return function cleanup() {
      document.body.classList.remove('checkout');
    };
  });

  useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && storeSlug) {
        await dispatch(fetchCheckout(checkoutId, storeSlug, decrypt(id)));
      }
    };
    getCheckout();
  }, [checkoutId, storeSlug, id]);

  useEffect(() => {
    const isComplete = isCheckoutComplete(get(checkout, 'state'));
    if (isComplete || (isComplete && id && isValidCustomer(checkoutCustomer))) {
      const thankYouPageUrl = `/thankyou?name=${get(checkoutCustomer, 'firstName')}
        &orderNumber=${get(checkout, 'id')}
        &arrivalEstimate=${get(selectedDeliveryOption, 'estimatedDelivery.arrivalDate')}
        &orderLookupNumber=${get(checkout, 'orderLookupNumber')}`;
      history.push(thankYouPageUrl);
    }
  }, [checkout, id, deliveryOptions]);

  useEffect(() => {
    if (!isEmpty(cartLineItems) && !pageViewTriggered) {
      tracker.track('checkout.viewed', {
        storeId: stores.id,
        storeSlug,
        cartTotal,
        cartLineItemCount: cartLineItemsQuantity,
        loggedInStatus: false,
        lineItems: cartLineItems.map(lineItem => lineItem.sku),
        productIds: cartLineItems.map(lineItem => lineItem.variantData.productId),
        sellerId: stores.sellerId,
        currency: localizationData.buyer_currency,
        domain: window.location.origin
      });
      setPageViewTriggered(true);
    }
  }, [cartLineItems, pageViewTriggered]);

  const addActiveStep = (...args) => {
    const newActiveSteps = activeSteps.slice();
    args.forEach((stepId) => {
      newActiveSteps.push(stepId);
    });

    setActiveSteps(newActiveSteps);
  };

  const passThroughProps = {
    activeSteps,
    setActiveSteps,
    addActiveStep,
    checkoutId,
    addressHasBeenEntered,
    setAddressHasBeenEntered
  };

  useEffect(() => {
    setTotal(convertToDollars(checkout?.costs?.total?.value));
  }, [checkout]);

  useEffect(() => {
    manageZendeskVisibility(false);
  }, [bpIsGT('mobileLg')]);
  return (
    <PayPalScriptProvider options={ { 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID } }>
      <div className="checkoutpage">
        <ModalConductor
          activeModal={ activeModal }
          setActiveModal={ state => dispatch(setActiveModal(state)) }
          transitionOrigin="bottom"
          modals={ [{
            id: 'mobile-cart-summary',
            className: 'mobile-cart-summary-modal',
            node: (
              <CheckoutCartInfoModal
                cartLineItems={ cartLineItems }
                showAddressMessage={ showAddressMessage }
                showPromoCode={ showPromoCode }
              />
            )
          }] }
        />
        <SEO
          title={ `Checkout | ${stores.name}` }
        />
        <CheckoutHeader />

        { storeListings.isFetchingCart ? (
          <div className="checkout__loader">
            <Loader />
          </div>
        ) : (
          <>
            { bpIsLT('tabletSm') && (
              <button
                className="mobilecartsummary__toggle"
                type="button"
                onClick={ () => dispatch(setActiveModal('mobile-cart-summary')) }
              >
                <span className="mobilecartsummary__toggle__cartlineitems">{ `Your cart (${cartLineItems.length})` }</span>
                <span className="mobilecartsummary__toggle__price">
                  <Price value={ total } />
                  <Icon name="ChevronDown" size={ 18 } />
                </span>
              </button>
            ) }

            <div className="checkout__wrapper">
              <div className="checkout__inner">
                <main className="checkout__main">
                  <h2>Checkout</h2>
                  <CheckoutDetails
                    customer={ checkout?.customer }
                    setShowAddressMessage={ setShowAddressMessage }
                    setShowPromoCode={ setShowPromoCode }
                    { ...passThroughProps }
                  />

                  <CheckoutDeliveryOptions { ...passThroughProps } />

                  <CheckoutPayment { ...passThroughProps } />
                </main>

                { bpIsGT('tabletSm') && (
                  <aside className="checkout__aside">
                    <CheckoutCartInfo
                      showAddressMessage={ showAddressMessage }
                      showPromoCode={ showPromoCode }
                    />
                  </aside>
                ) }
              </div>
            </div>
          </>
        )}
      </div>
    </PayPalScriptProvider>
  );
};

export default CheckoutPage;
