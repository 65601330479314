import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { convertHashToParams } from 'utils/themeUtils';
import CryptoJS from 'crypto-js';
import { handleErrors } from '../utils/requestUtils';
import { setJsonVariables } from '../utils/themeParser';
import store from '../store'

const baseS3Path = 'https://premium-storefronts.s3.amazonaws.com/storefronts';
const basePath = process.env.REACT_APP_ASSET_PATH || baseS3Path;
const baseLambdaThemePath = 'https://kxqd7cf966.execute-api.us-west-1.amazonaws.com/dev/themes';
const getThemeLambdaPath = process.env.REACT_APP_THEME_LAMBDA_PATH || baseLambdaThemePath;

// Setup defaultTheme
const defaultTheme = require('./default-theme/theme-config.json');
const storeName = process.env.REACT_APP_WEBSITE_NAME || store.slug;
defaultTheme.meta.storeName = storeName;
defaultTheme.content.heroBanner.title = storeName;
// Cannot do this in-line due to scoping issues
const fallback = JSON.stringify(defaultTheme);

// Set variables to be replaced in template config
const themeVariables = {
  assetPath: `${basePath}/${store.slug}/assets` // No trailing slash
}

export async function  getThemeConfig(storeId, accessCode) {
  const accessCodeFragment = accessCode ? `&accessCode=${accessCode}` : '';
  const dataUrl = `${getThemeLambdaPath}?siteKey=${storeId}${accessCodeFragment}`;
  let themeData = null;

  await fetch(dataUrl)
    .then(handleErrors)
    .then(
      (response) => {
        return response;
      },
      // Handle case where theme config is not found
      // Load default theme
      () => {
        // Must bring variable into local scope
        const fb = fallback;
        return Promise.resolve(new Response(fb));
      }
    )
    .then(handleErrors)
    .then(
      response => response.json(),
      (error) => { throw new Error(`An error occured: ${error}`); }
    )
    .then(json => {
      let themeJson = json?.statusCode === 404 ? JSON.parse(fallback) : json
      themeData = setJsonVariables(JSON.stringify(themeJson), themeVariables);
      themeData = JSON.parse(themeData);
    });

  return themeData;
}

export const getPreviewThemeData = (location, initThemeData) => {
  let isPreviewMode = false;
  let data = initThemeData;

  if (location.hash) {
    const initialThemeData = cloneDeep(initThemeData);;
    const hashParams = convertHashToParams(location.hash);
    const previewData = get(hashParams, 'previewData');
    const secretKey = process.env.REACT_APP_PREVIEW_SECRET_KEY;

    if (previewData && !secretKey) {
      // eslint-disable-next-line no-console
      console.warn('The REACT_APP_PREVIEW_SECRET_KEY env variable is missing. The previewData will not be decrypted properly.');
    }

    let decryptedData = {};
    if (previewData && secretKey) {
      const cipher = previewData;
      const bytes = CryptoJS.AES.decrypt(cipher, secretKey);
      const bytesStr = bytes.toString(CryptoJS.enc.Utf8);
      decryptedData = bytesStr ? JSON.parse(bytesStr) : {};
    }

    isPreviewMode = get(decryptedData, 'preview', false);
    const combinedThemeData = { ...decryptedData };
    const newThemeData = {};

    Object.keys(combinedThemeData).forEach((key) => {
      if (combinedThemeData[key] === 'null') {
        set(newThemeData, key, '');
      } else {
        const value = combinedThemeData[key];
        set(newThemeData, key, value);
      }
    });

    data = merge(initialThemeData, newThemeData);
  }

  return { mergedData: data,  isPreviewMode };
}
